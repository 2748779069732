<template>
	<div class="support">
		<h1>Поддержка</h1>
		<v-row class="mt-4">
			<v-col cols="12" sm="6" md="6">
				<v-textarea
					filled
					label="Текст вопроса или сообщения"
					auto-grow
					placeholder="Мы будем рады Вашему вопросу или сообщению."
				></v-textarea>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" sm="6" md="6">
				<v-file-input
					label="Прикрепить файл"
					filled
				></v-file-input>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" sm="4" md="3">
				<v-btn color="primary" block>Отправить</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "support"
}
</script>

<style lang="scss">

</style>